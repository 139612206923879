import SimplexNoise from 'simplex-noise';
import { debounce } from "debounce";
import * as PIXI from 'pixi.js'
// @ts-ignore
import * as hsl from 'hsl-to-hex';
import {KawaseBlurFilter} from "@pixi/filter-kawase-blur";

function random(min: number, max:number) {
  return Math.random() * (max - min) + min;
}

// map a number from 1 range to another
function map(n: number, start1: number, end1: number, start2: number, end2: number) {
  return ((n - start1) / (end1 - start1)) * (end2 - start2) + start2;
}

// Create a new simplex noise instance
const simplex = new SimplexNoise();

export class Orb {
  bounds
  x
  y
  scale
  fill
  radius
  xOff
  yOff
  inc
  graphics

  constructor(fill = String(0x000000)) {
    // bounds = the area an orb is "allowed" to move within
    this.bounds = this.setBounds();
    // initialise the orb's { x, y } values to a random point within it's bounds
    this.x = random(this.bounds["x"].min, this.bounds["x"].max);
    this.y = random(this.bounds["y"].min, this.bounds["y"].max);

    // how large the orb is vs it's original radius (this will modulate over time)
    this.scale = 1;

    // what color is the orb?
    this.fill = fill;

    // the original radius of the orb, set relative to window height
    this.radius = random(window.innerHeight / 9, window.innerHeight / 5);

    // starting points in "time" for the noise/self similar random values
    this.xOff = random(0, 1000);
    this.yOff = random(0, 1000);
    // how quickly the noise/self similar random values step through time
    this.inc = 0.002;

    // PIXI.Graphics is used to draw 2d primitives (in this case a circle) to the canvas
    this.graphics = new PIXI.Graphics();
    this.graphics.alpha = 0.825;

    // 250ms after the last window resize event, recalculate orb positions.
    window.addEventListener(
      "resize",
      debounce(() => {
        this.bounds = this.setBounds();
      }, 250)
    );
  }

  setBounds() {
    // how far from the { x, y } origin can each orb move
    const maxDist =
      window.innerWidth < 1000 ? window.innerWidth / 3 : window.innerWidth / 5;
    // the { x, y } origin for each orb (the bottom right of the screen)
    const originX = window.innerWidth / 1.25;
    const originY =
      window.innerWidth < 1000
        ? window.innerHeight / 3
        : window.innerHeight / 5;

    // allow each orb to move x distance away from it's x / y origin
    return {
      x: {
        min: originX - maxDist,
        max: originX + maxDist
      },
      y: {
        min: originY - maxDist,
        max: originY + maxDist
      }
    };
  }

  update() {
    // self similar "pseudo-random" or noise values at a given point in "time"
    const xNoise = simplex.noise2D(this.xOff, this.xOff);
    const yNoise = simplex.noise2D(this.yOff, this.yOff);
    const scaleNoise = simplex.noise2D(this.xOff, this.yOff);

    // map the xNoise/yNoise values (between -1 and 1) to a point within the orb's bounds
    this.x = map(xNoise, -1, 1, this.bounds["x"].min, this.bounds["x"].max);
    this.y = map(yNoise, -1, 1, this.bounds["y"].min, this.bounds["y"].max);
    // map scaleNoise (between -1 and 1) to a scale value somewhere between half of the orb's original size, and 100% of it's original size
    this.scale = map(scaleNoise, -1, 1, 0.5, 1);

    // step through "time"
    this.xOff += this.inc;
    this.yOff += this.inc;
  }

  render() {
    // update the PIXI.Graphics position and scale values
    this.graphics.x = this.x;
    this.graphics.y = this.y;
    this.graphics.scale.set(this.scale);

    // clear anything currently drawn to graphics
    this.graphics.clear();

    // tell graphics to fill any shapes drawn after this with the orb's fill color
    this.graphics.beginFill(Number(this.fill));
    // draw a circle at { 0, 0 } with it's size set by this.radius
    this.graphics.drawCircle(0, 0, this.radius);
    // let graphics know we won't be filling in any more shapes
    this.graphics.endFill();
  }
}

export class ColorPalette {
  hue: number = 0
  complimentaryHue1: number = 0
  complimentaryHue2: number = 0
  saturation: number = 0;
  lightness: number = 0
  baseColor: string = '';
  complimentaryColor1: string = '';
  complimentaryColor2: string = '';
  colorChoices: string[] = [];

  constructor() {
    this.setColors();
    this.setCustomProperties();
  }

  setColors() {
    // pick a random hue somewhere between 220 and 360
    this.hue = ~~random(220, 360);
    this.complimentaryHue1 = this.hue + 30;
    this.complimentaryHue2 = this.hue + 60;
    // define a fixed saturation and lightness
    this.saturation = 95;
    this.lightness = 50;

    // define a base color
    this.baseColor = hsl(this.hue, this.saturation, this.lightness);
    // define a complimentary color, 30 degress away from the base
    this.complimentaryColor1 = hsl(
      this.complimentaryHue1,
      this.saturation,
      this.lightness
    );
    // define a second complimentary color, 60 degrees away from the base
    this.complimentaryColor2 = hsl(
      this.complimentaryHue2,
      this.saturation,
      this.lightness
    );

    // store the color choices in an array so that a random one can be picked later
    this.colorChoices = [
      this.baseColor,
      this.complimentaryColor1,
      this.complimentaryColor2
    ];
  }

  randomColor() {
    // pick a random color
    return this.colorChoices[~~random(0, this.colorChoices.length)].replace(
      "#",
      "0x"
    );
  }

  setCustomProperties() {
    // set CSS custom properties so that the colors defined here can be used throughout the UI
    document.documentElement.style.setProperty("--hue", String(this.hue));
    document.documentElement.style.setProperty(
      "--hue-complimentary1",
      String(this.complimentaryHue1)
    );
    document.documentElement.style.setProperty(
      "--hue-complimentary2",
      String(this.complimentaryHue2)
    );
  }
}

export function startMainPageOrbAnimation() {
  const app = new PIXI.Application({
    resizeTo: window,
    backgroundAlpha: 0,
  });

  const colorPalette = new ColorPalette();
  app.stage.filters = [new KawaseBlurFilter(30, 10, true)];
  const orbs: Orb[] = [];

  for (let i = 0; i < 3; i++) {
    //const orb = new Orb(colorPalette.randomColor());
    const orb = new Orb('0xAF82EE')
    app.stage.addChild(orb.graphics);
    orbs.push(orb);
  }

  app.ticker.add(() => {
    orbs.forEach((orb) => {
      orb.update();
      orb.render();
    });
  });

  app.view.classList.add('orb-canvas')
  document.querySelector('.home')?.appendChild(app.view)
}

export const headerMenuOffsetController = (headerEl: HTMLHeadingElement, introH: number) => {
  window.onscroll = () => {
    let scrollOffset = window.scrollY;

    if (scrollOffset >= introH) {
      headerEl.classList.add('fixed');
    } else {
      headerEl.classList.remove('fixed');
    }
  }

  let scrollOffset = window.scrollY;

  if (scrollOffset >= introH) {
    headerEl.classList.add('fixed');
  } else {
    headerEl.classList.remove('fixed');
  }
}

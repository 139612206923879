import styles from './divider.module.css'
import React from "react";
import {DividerProps} from "../../utils/PropsTypes";

const Divider:React.FC<DividerProps> = ({margin}) => {
  return (
    <div className={styles.divider} style={{
      margin: margin
    }} />
  )
}

export {Divider}
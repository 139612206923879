import React from 'react';
import Link from "next/link";
import {MenuProps} from "../../utils/PropsTypes";

const Menu: React.FC<MenuProps> = ({items, openMenu}) => {
    return (
        <div className={'menu'}>
           {/* <div className={'blur'} />*/}
            <div className={'menu__content'}>
                <ul>
                    {items.map(item =>
                        <li key={item.link}>
                            <Link href={item.link}>
                                <a className={'nav__link'}>
                                    {item.title}
                                </a>
                            </Link>
                        </li>
                    )}
                </ul>
            </div>

            <div
                className={'close-bg'}
                onClick={() => {
                    openMenu(false)
                }}
            />
        </div>
    );
};

export default Menu;